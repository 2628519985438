import React from "react";

export default function Logo(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src="/favicon.png"
        style={{ maxHeight: "45px", marginRight: "15px" }}
        alt="Colossyan logo"
      />
      <h3 style={{ margin: 0 }}>Colossyan API (Beta) Documentation</h3>
    </div>
  );
}
